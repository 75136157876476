import { Box } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default function ScrollButton() {

    const openWhatsApp = () => {
        // You can replace '1234567890' with the actual phone number you want to open in WhatsApp
        const phoneNumber = '7451901516';
        const whatsappLink = `https://wa.me/${phoneNumber}`;

        window.open(whatsappLink, '_blank');
    };


    return (
        <Box position="fixed" bottom="50px" right="3%">
            <WhatsAppIcon
                style={{
                    padding: "5px",
                    background: "#25D366", // WhatsApp's green color
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    color: "white",
                    cursor: "pointer", // Add cursor pointer to indicate it's clickable
                }}
                onClick={openWhatsApp}
            />
        </Box>
    );
}
