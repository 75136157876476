import { Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export default function ModalForm({ handleCloseModal }) {
  const form = useRef();
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');

  const validateForm = () => {
    let isValid = true;

    // Name validation
    if (!form.current.user_name.value.trim()) {
      setNameError('Name is required');
      isValid = false;
    } else {
      setNameError('');
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!form.current.user_email.value.trim() || !emailRegex.test(form.current.user_email.value.trim())) {
      setEmailError('Valid email is required');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Mobile Number validation (assuming a simple numeric validation)
    const mobileRegex = /^\d+$/;
    if (!form.current.message.value.trim() || !mobileRegex.test(form.current.message.value.trim())) {
      setMobileError('Valid mobile number is required');
      isValid = false;
    } else {
      setMobileError('');
    }

    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs.sendForm('service_26h0sex', 'template_fzzk6cq', form.current, 'qePSBw5Ebt-UUUvFb')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });

      e.target.reset();
      handleCloseModal();
    }
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <Stack my={3} spacing={3} direction={"column"} alignItems="flex-start">
          <TextField
            type="text"
            name="user_name"
            label="Name"
            variant="standard"
            fullWidth
            sx={{ mb: 2 }}
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            type="email"
            name="user_email"
            label="Email"
            variant="standard"
            fullWidth
            sx={{ mb: 2 }}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            name="message"
            label="Mobile Number"
            variant="standard"
            fullWidth
            sx={{ mb: 2 }}
            error={!!mobileError}
            helperText={mobileError}
          />
          <FormControlLabel
            control={<Checkbox defaultChecked color="secondary" />}
            label="Agree to terms and conditions"
            sx={{ mb: 2 }}
          />
          <Stack>
            <Button type="submit" value="Send" fullWidth variant="contained" color="primary" sx={{
              background: "var(--color-bg) ", ":hover": {
                background: "var(--color-bg)"
              }
            }}>
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
}
