import React, { useEffect, useState } from "react";
import "./navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import CloseIcon from '@mui/icons-material/Close';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import lg from "../../assets/lg.png"
import { Typography, Modal, Box, IconButton, Stack, } from '@mui/material';
import ModalForm from "../modalForm/ModalForm";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => {
    setOpenModal(true);
    setShowMediaIcons(false); // Optionally close the menu when the modal opens
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const [firstVisit, setFirstVisit] = useState(true);
  useEffect(() => {
    // Open the modal after 5 seconds on the first visit
    if (firstVisit) {
      const timeoutId = setTimeout(() => {
        handleModalOpen();
        setFirstVisit(false);
      }, 5000);

      // Clear the timeout if the component is unmounted
      return () => clearTimeout(timeoutId);
    }
  }, [firstVisit]);
  const handleClick = () => {
    const phoneNumber = '7451901516'; // Replace with the actual phone number
  
    // Try to open the phone app with the tel: scheme
    window.open(`tel:${phoneNumber}`, '_self');
  };
  return (
    <>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
          <img src={lg} alt="" />
        </div>
        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
              <a onClick={() => setShowMediaIcons(!showMediaIcons)} style={{ color: "black" }} href="#">Overview</a>
            </li>
            <li>
              <a onClick={() => setShowMediaIcons(!showMediaIcons)} style={{ color: "black" }} href="#keyfeature">Key features</a>
            </li>
            <li>
              <a onClick={handleModalOpen} style={{ color: "black" }} href="#download">Brochure</a>
            </li>
            <li>
              <a onClick={() => setShowMediaIcons(!showMediaIcons)} style={{ color: "black" }} href="#plan" >Plan</a>
            </li>
            <li>
              <a onClick={() => setShowMediaIcons(!showMediaIcons)} style={{ color: "black" }} href="#pricing" >Pricing</a>
            </li>
          </ul>
        </div>


        <div className="social-media">
          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>


      {/* Dialog for the modal */}

      {/* Modal */}
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: "10px",
            width: '90%', // Adjust the width as needed for small screens
            maxWidth: '400px', // Set a maximum width to prevent it from getting too wide
          }}
        >
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography variant="h5"> Enquiry now</Typography>
            <IconButton onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box>
            <Stack my={2}>
              <Typography>
                Please fill your details below we will get in touch shortly.
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-evenly" alignItems="center">
              <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <IconButton onClick={handleClick}>
                  <AddIcCallIcon />
                </IconButton>
                <Typography>
                  Instant Call
                </Typography>
              </Box>
              <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <IconButton>
                  <CurrencyRupeeIcon />
                </IconButton>
                <Typography>
                  Best price
                </Typography>
              </Box>
            </Stack>
            <ModalForm handleCloseModal={handleModalClose} />
          </Box>
        </Box>
      </Modal>

    </>
  );
};

export default Navbar;
