import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Button, Stack, TextField } from '@mui/material';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Regular expressions for validation
    const nameRegex = /^[a-zA-Z\s]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    // Get form values
    const name = e.target.user_name.value.trim();
    const email = e.target.user_email.value.trim();
    const message = e.target.message.value.trim();

    // Validate name
    if (!name || !nameRegex.test(name)) {
      console.error('Please enter a valid name');
      return;
    }

    // Validate email
    if (!email || !emailRegex.test(email)) {
      console.error('Please enter a valid email address');
      return;
    }

    // Validate mobile number
    if (!message || !mobileRegex.test(message)) {
      console.error('Please enter a valid mobile number');
      return;
    }

    // If all validations pass, send the email
    emailjs
      .sendForm('service_26h0sex', 'template_fzzk6cq', form.current, 'qePSBw5Ebt-UUUvFb')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <Box my={2} sx={{ background: "var(--color-white)", p: 3, borderRadius: "10px" }}>
          <TextField
            type="text"
            name="user_name"
            label="Name"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            required
          />
          <TextField
            type="email"
            name="user_email"
            label="Email"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            required
          />
          <TextField
            name="message"
            label="Mobile Number"
            variant="outlined"
            fullWidth
            sx={{ mb: 2 }}
            required
          />
          <Stack>
            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <Button
                type="submit"
                value="Send"
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  background: "var(--color-primary)",
                  '&:hover': {
                    background: 'var(--color-primary)',
                    color: "black",
                    fontWeight: "bold"
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Stack>
        </Box>
      </form>
    </>
  );
};

export default Contact;
