import { Box, Button, Container, IconButton, Modal, Stack, Typography } from '@mui/material';
import * as React from 'react';
import Table from '@mui/material/Table';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModalForm from '../modalForm/ModalForm';
export default function Pricing() {
    const [openModal, setOpenModal] = React.useState(false);

    const handleModalClose = () => {
        setOpenModal(false);
    };
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const listItemTextStyle = { color: '#fff' };
    const starIconStyle = {
        color: '#7CFC00', // Set the color to white
    };
    const handleClick = () => {
        const phoneNumber = '7451901516'; // Replace with the actual phone number
        // Try to open the phone app with the tel: scheme
        window.open(`tel:${phoneNumber}`, '_self');
    };
    return (
        <>
            <section id='pricing'>
                <Container maxWidth="xl" sx={{ my: 3 }}>
                    <Stack sx={{ textAlign: "center", my: 3 }}>
                        <Typography sx={{ fontWeight: "bold" }} variant='h3'>Pricing</Typography>
                        <Box sx={{ mt: 1, width: '100%', height: '2px', backgroundColor: 'black', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)' }} />
                    </Stack>
                    <Container maxWidth="xl">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead style={{ fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>
                                    <TableRow>
                                        <TableCell align="center"> <Typography sx={{ fontWeight: "bold" }}>BHK Type</Typography> </TableCell>
                                        <TableCell align="center"> <Typography sx={{ fontWeight: "bold" }}>Sizes</Typography> </TableCell>
                                        <TableCell align="center"> <Typography sx={{ fontWeight: "bold" }}>Price</Typography> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontSize: '20px' }}>2 BHK</TableCell>
                                        <TableCell align="center" sx={{ fontSize: '20px' }}>1137 + Sqft.</TableCell>
                                        <TableCell align="center">
                                            <Stack>
                                                <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                    <Button onClick={handleOpenModal}
                                                        type="submit"
                                                        value="Send"
                                                        variant="contained"
                                                        sx={{
                                                            fontWeight: "bold",
                                                            color: "black",
                                                            background: "var(--color-primary)",
                                                            '&:hover': {
                                                                background: 'var(--color-primary)',
                                                                color: "black",
                                                                fontWeight: "bold"
                                                            },
                                                        }}
                                                    >
                                                        Request Price
                                                    </Button>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontSize: '20px' }}>3 BHK</TableCell>
                                        <TableCell align="center" sx={{ fontSize: '20px' }}>1404 + Sqft.</TableCell>
                                        <TableCell align="center"> <Stack>
                                            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                                <Button onClick={handleOpenModal}
                                                    type="submit"
                                                    value="Send"
                                                    variant="contained"
                                                    sx={{
                                                        fontWeight: "bold",
                                                        color: "black",
                                                        background: "var(--color-primary)",
                                                        '&:hover': {
                                                            background: 'var(--color-primary)',
                                                            color: "black",
                                                            fontWeight: "bold"
                                                        },
                                                    }}
                                                >
                                                    Request Price
                                                </Button>
                                            </Box>
                                        </Stack></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Container>
            </section>
            <Modal open={openModal} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: "10px",
                        width: '90%', // Adjust the width as needed for small screens
                        maxWidth: '400px', // Set a maximum width to prevent it from getting too wide
                    }}
                >
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant="h5"> Enquiry now</Typography>
                        <IconButton onClick={handleModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Box>
                        <Stack my={2}>
                            <Typography>
                                Please fill your details below we will get in touch shortly.
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                <IconButton onClick={handleClick}>
                                    <AddIcCallIcon />
                                </IconButton>
                                <Typography>
                                    Instant Call
                                </Typography>
                            </Box>
                            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                <IconButton>
                                    <CurrencyRupeeIcon />
                                </IconButton>
                                <Typography>
                                    Best price
                                </Typography>
                            </Box>
                        </Stack>
                        <ModalForm handleCloseModal={handleModalClose} />
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
