import { Box } from '@mui/material'
import React from 'react'
export default function GoogleMaps() {
    return (
        <>
<Box my={3}>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d438.30577383718366!2d77.40162085247094!3d28.49622050310029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce97cabbb91cf%3A0x414ecf5b1f65e37d!2sABC%20tower%20gate%20no%205!5e0!3m2!1sen!2sin!4v1703831775333!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</Box>
        </>
    )
}
