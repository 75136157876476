import { Container, Stack, Typography } from '@mui/material'
import React from 'react'
import Contact from '../contact/Contact'
export default function Footersection() {
  const handleClick = () => {
    const phoneNumber = '+917451901516'; // Replace with the actual phone number

    // Try to open the phone app with the tel: scheme
    window.open(`tel:${phoneNumber}`, '_self');
  };

  return (
    <>
      <section>
        <Container maxWidth="xl" sx={{ my: 3 }}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography variant='h4'>
              Disclaimer
            </Typography>
            <Typography>
              Eldeco Live by the Greens Phase-I, Eldeco Live by the Greens Phase-II and Eldeco Live by the Greens Phase-III are being developed by Wondrous Buildmart Pvt. Ltd. (Wondrous) having CIN No.: U70102UP20160PTC077236, Registered Office Plot No.: 1B, 6th floor, ACE Studio, Sector-126, Noida, Gautam Budh Nagar, Uttar Pradesh - 201301 which form part of the total land measuring approx. 20 acres being developed in a phased manner over a period of time. Nothing contained herein intends to constitute a legal offering or legal binding on the part of Wondrous.
            </Typography>
            <Typography>
              The premium township comes fitted with not just the privileges you need, but even those you still haven't thought about.
            </Typography>
            <Typography fontWeight={"bold"}>
              Premium 2 & 3 BHK Apartments starting from Rs. 1.13 Cr*.
            </Typography>
            <Typography>
              Site Address
              SC-02/A9, SECTOR 150, NOIDA, UTTAR PRADESH 201310
            </Typography>
          </Stack>
        </Container>
        <Stack justifyContent={"center"} alignItems={"center"} sx={{ background: "var(--color-bg)", color: "var(--color-white) ", p: 3 }}>
          <Typography variant='h5'>
            BOOK A FREE SITE VISIT NOW
          </Typography>
          <Typography onClick={handleClick} style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }}>
            Call +917451901516
          </Typography>
          <Contact />
        </Stack>
      </section >
    </>
  )
}
