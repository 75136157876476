
import './App.css';
import ScrollButton from './component/ScrollTop/ScrollButton';
// import Brochure from './component/brochure/Brochure';
import Footersection from './component/footer/Footersection';
import GoogleMaps from './component/googleMap/GoogleMaps';
import Keyfeatures from './component/keyFeature/Keyfeatures';
import Navbar from './component/navbar/Navbar';
import OverView from './component/overview/OverView';
import Plan from './component/plan/Plan';
import Pricing from './component/priceing/Pricing';
function App() {
  return (
    < >
    
      <Navbar />
      <OverView />
      <Keyfeatures />
      {/* <Brochure /> */}
      <Plan />
      <Pricing />
      <GoogleMaps />
      <Footersection />
      <ScrollButton/>
    </>
  );
}

export default App;
