import img1 from "./img1.jpg"
import img2 from "./img2.jpg"
import img88 from "./img88.jpeg"
import img3 from "./img3.jpg"
import img4 from "./img4.jpg"
import img5 from "./img5.jpg"
import img6 from "./img6.jpg"
let data_product = [
  {
    id:1,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:img1,
    new_price:50.00,
    old_price:80.50,
  },
  {id:2,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:img88,
    new_price:85.00,
    old_price:120.50,
  },
  {id:3,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:img3,
    new_price:60.00,
    old_price:100.50,
  },
  {id:4,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:img4,
    new_price:100.00,
    old_price:150.00,
  },
  {id:5,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:img5,
    new_price:100.00,
    old_price:150.00,
  },
  {id:6,
    name:"Striped Flutter Sleeve Overlap Collar Peplum Hem Blouse",
    image:img6,
    new_price:100.00,
    old_price:150.00,
  },
];

export default data_product;
