import React, { useState } from 'react'
import banner from "../../assets/banner.jpg"
import "./overview.css"
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import overview from "../../assets/overview.jpg"
import { Box, Stack, Button, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Modal, Typography, IconButton } from '@mui/material'
import ModalForm from '../modalForm/ModalForm';
export default function OverView() {
    const [openModal, setOpenModal] = useState(false);

    const handleModalClose = () => {
        setOpenModal(false);
    };
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const listItemTextStyle = { color: '#fff' };
    const starIconStyle = {
        color: '#7CFC00', // Set the color to white
    };
    const handleClick = () => {
        const phoneNumber = '7451901516'; // Replace with the actual phone number

        // Try to open the phone app with the tel: scheme
        window.open(`tel:${phoneNumber}`, '_self');
    };
    return (
        <>
            <section id='overview' style={{ position: 'relative', width: '100%', height: '100%', background: "var(--color-bg)" }}>
                <img
                    src={banner}
                    alt=""
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
                <Container maxWidth={"xl"}>
                    <Grid container spacing={2} p={2}>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Box>
                                <img src={overview} alt="Overview" style={{ maxWidth: '100%', height: 'auto' }} />
                            </Box>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <Typography variant='h5' sx={{ fontWeight: "bold", color: "white" }}>OVERVIEW</Typography>
                            <Typography variant='h4' sx={{ fontWeight: "bold", color: "#7CFC00" }}>Live By The Green </Typography>
                            <Typography sx={{ color: "white" }}>
                                Wake up every morning to be greeted by the freshest of the hues, and end your day witnessing the sun go down over the open greens. Embrace a new life and change to your new home - Eldeco Live By The Greens A new residential space in the heart of Noida. Project has a large cricket ground and academy in the center. It is the only such kind of exclusive project in North India. The project infrastructure is an ideal combination of contemporary architecture and features to provide a comfortable living with numerous facilities to relax and fit within the complex. It is one of the highest selling project in Noida region and has seen good price appreciation. Project has lot of pricing upside potential and makes it the investment worthy.
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <StarIcon style={starIconStyle} />
                                    </ListItemIcon>
                                    <ListItemText style={listItemTextStyle}>
                                        2 & 3 BHK homes in Noida with 80% greenery in 20 acres of land
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <StarIcon style={starIconStyle} />
                                    </ListItemIcon>
                                    <ListItemText style={listItemTextStyle}>
                                        World-class sports facilities including a cricket academy by Rohit Sharma
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <StarIcon style={starIconStyle} />
                                    </ListItemIcon>
                                    <ListItemText style={listItemTextStyle}>
                                        Experience a sense of arrival at the well-appointed entrance lobby
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <StarIcon style={starIconStyle} />
                                    </ListItemIcon>
                                    <ListItemText style={listItemTextStyle}>
                                        Swim a lap or two in the swimming pool and bask on the loungers
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <StarIcon style={starIconStyle} />
                                    </ListItemIcon>
                                    <ListItemText style={listItemTextStyle}>
                                        Chat with neighbors at sundown under a cabana
                                    </ListItemText>
                                </ListItem>
                            </List>
                            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                <Button onClick={handleOpenModal} variant="outlined" color="primary" sx={{
                                    fontWeight: "bold",
                                    fontSize: "1.5rem", color: "var(--color-primary)", '&:hover': {
                                        background: 'var(--color-white)',
                                        color: "black",
                                        fontWeight: "bold"
                                    },
                                }} >
                                    Download Brochure
                                </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </section>


            {/* Modal */}
            <Modal open={openModal} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: "10px",
                        width: '90%', // Adjust the width as needed for small screens
                        maxWidth: '400px', // Set a maximum width to prevent it from getting too wide
                    }}
                >
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Typography variant="h5"> Enquiry now</Typography>
                        <IconButton onClick={handleModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Box>
                        <Stack my={2}>
                            <Typography>
                                Please fill your details below we will get in touch shortly.
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-evenly" alignItems="center">
                            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                <IconButton onClick={handleClick}>
                                    <AddIcCallIcon />
                                </IconButton>
                                <Typography>
                                    Instant Call
                                </Typography>
                            </Box>
                            <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                <IconButton>
                                    <CurrencyRupeeIcon />
                                </IconButton>
                                <Typography>
                                    Best price
                                </Typography>
                            </Box>
                        </Stack>
                        <ModalForm handleCloseModal={handleModalClose} />
                    </Box>
                </Box>
            </Modal>

        </>
    )
}


