import { Box, Button, Container,  IconButton, Menu, MenuItem, Stack,  } from '@mui/material'
import React, { useState } from 'react'
import img55 from "../../assets/img55.jpg"
import img66 from "../../assets/img66.jpg"
import img77 from "../../assets/img77.jpg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
export default function Plan() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState("2BHK type(A1)");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (item) => {
        setSelectedItem(item);
        setAnchorEl(null);
    };

    return (
        <>
            <section id='plan'>
                <Container maxWidth="lg" sx={{ my: 3 }}>

                    {/* <Stack sx={{ textAlign: "center", }}>
                        <Typography sx={{ fontWeight: "bold" }} justifyContent={"center"} variant='h3'>Plan</Typography>
                    </Stack> */}
                    <Box>
                        <Box sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <Button
                                onClick={handleClick}
                                variant="outlined"
                                color="primary"
                                sx={{
                                    fontSize: "1.5rem",
                                    color: "var(--color-primary)",
                                    '&:hover': {
                                        background: 'var(--color-white)',
                                        color: "black",
                                    },
                                }}
                            >
                                Select Plan type
                                <IconButton>
                                    <ExpandMoreIcon />
                                </IconButton>
                            </Button>
                        </Box>
                        <Stack >
                            {selectedItem === "2BHK type(A1)" && (
                                <Box>
                                    <img
                                        src={img55}
                                        alt="Image"
                                        style={{ maxWidth: '100%', height: 'auto', width: '100%' }}
                                    />
                                </Box>
                            )}
                            {selectedItem === "2BHK type(A2)" && (
                                <Box>
                                    <img
                                        src={img66}
                                        alt="Image"
                                        style={{ maxWidth: '100%', height: 'auto', width: '100%' }}
                                    />
                                </Box>
                            )}
                            {selectedItem === "3BHK type(B)" && (
                                <Box>
                                    <img
                                        src={img77}
                                        alt="Image"
                                        style={{ maxWidth: '100%', height: 'auto', width: '100%' }}
                                    />
                                </Box>
                            )}
                        </Stack>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => handleMenuItemClick("2BHK type(A1)")}>
                                <ListItemText primary="2BHK type(A1)" />
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick("2BHK type(A2)")}>
                                <ListItemText primary="2BHK type(A2)" />
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick("3BHK type(B)")}>
                                <ListItemText primary="3BHK type(B)" />
                            </MenuItem>
                        </Menu>
                    </Box>
                </Container>

            </section>
        </>
    )
}
