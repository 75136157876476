import React from 'react'
import data_product from "../../assets/data_product"

import { Box, Container, Grid, Paper, Stack, Typography } from '@mui/material'
export default function Keyfeatures() {
  return (
    <>
      <section id='keyfeature'>
        <Container maxWidth="xl" sx={{my:3}}>
          <Stack sx={{ textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold" }} variant='h3'>Key Features</Typography>
            <Box   sx={{mt:1, width: '100%', height: '2px', backgroundColor: 'black', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.5)' }} />
          </Stack>
          <Grid container spacing={2} mt={2}>
            {
              data_product.map((item, index) => {
                return (
                  <Grid item sm={12} xs={12} md={6} lg={6} key={index}>
                    <Paper elevation={3} className='imageLink' >
                      <img
                        src={item.image}
                        alt=""
                        className='imageStyle'
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                      <Stack direction={"row"} spacing={3}>
                      </Stack>
                    </Paper>
                  </Grid>
                )
              })
            }
          </Grid>
        </Container>
      </section>
    </>
  )
}
